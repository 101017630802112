import React, { useState } from 'react';
import HubspotForm from 'react-hubspot-form';
import Section from '../../molecules/Section/Section';
import WaveBg from '../../molecules/Section/SectionBg/WaveBg';
import { colors } from '../../styles/atoms/colors';
import setValuesFromParams from '../Forms/utils/setValuesFromParams';
import {
  Form,
  Text,
  cardContainerStyles,
  cardSectionStyles,
  defaultContainerStyles,
  defaultSectionStyles,
} from './styles/Newsletter.styled';

const Newsletter = ({
  component: { headingKicker, heading, hubspotFormId, variant },
}) => {
  const [subscribed, setSubscribed] = useState(false);

  return (
    <Section
      style={variant === 'card' ? cardSectionStyles : defaultSectionStyles}
      innerStyle={
        variant === 'card' ? cardContainerStyles : defaultContainerStyles
      }
      backgroundImageName={variant === 'default' ? 'dark-neon' : undefined}
    >
      {variant === 'card' && (
        <WaveBg
          className="wave"
          width={261}
          height={141.47}
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeWidth={30}
          opacity={0.2}
        />
      )}
      {!subscribed && (
        <Text variant={variant}>
          <p className="heading-kicker">
            {headingKicker || 'Shopmonkey Newsletter'}
          </p>
          <h2 className="heading">
            {heading ||
              'Get access to the best advice and resources in auto shop management'}
          </h2>
        </Text>
      )}
      <Form id="hub-form" variant={variant}>
        <HubspotForm
          formId={hubspotFormId || '329845d7-49aa-44d2-b9a0-0dc4a96c9e6d'}
          onFormReady={setValuesFromParams}
          onFormSubmitted={(_form, data) => {
            if (!data.redirectUrl) {
              setSubscribed(true);
            }
          }}
          portalId="5424293"
        />
      </Form>
    </Section>
  );
};

export default Newsletter;
